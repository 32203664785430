export const SIGN_IN = 'SIGN_IN';

export const GET_INVITATION_DATA_REQUEST = 'GET_INVITATION_DATA_REQUEST';
export const GET_INVITATION_DATA_SUCCESS = 'GET_INVITATION_DATA_SUCCESS';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AGREEMENT_REQUEST = 'AGREEMENT_REQUEST';
export const ON_CODE_ERROR = 'ON_CODE_ERROR';
export const ON_SIGN_OUT = 'ON_SIGN_OUT';
export const CLEAR_INVITATION_ERROR = 'CLEAR_INVITATION_ERROR';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const RESET_PASSWORD_CODE_REQUEST = 'RESET_PASSWORD_CODE_REQUEST';
export const RESET_PASSWORD_CODE_SUCCESS = 'RESET_PASSWORD_CODE_SUCCESS';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';



export const CLEAR_AUTH_STATUSES = 'CLEAR_AUTH_STATUSES';
export const CLEAR_AUTH_STATUSES_STAY_SIGNED = 'CLEAR_AUTH_STATUSES_STAY_SIGNED';

export const ADMIN_SIGNED_IN = 'ADMIN_SIGNED_IN';
export const CREATE_NEW_SPRINT = 'CREATE_NEW_SPRINT';
export const GET_SPRINTS = 'GET_SPRINTS';
export const GET_SPRINTS_COMPANIES = 'GET_SPRINTS_COMPANIES';
export const SET_SPRINTS_COMPANIES = 'SET_SPRINTS_COMPANIES';
export const LOADING_SPRINTS = 'LOADING_SPRINTS';
export const SET_SPRINTS = 'SET_SPRINTS';
export const GET_COACH_SPRINT = 'GET_COACH_SPRINT';
export const GET_COACH_DATA = 'GET_COACH_DATA';
export const GET_COACH_DATA_MANY = 'GET_COACH_DATA_MANY';
export const SET_COACH_SPRINT = 'SET_COACH_SPRINT';
export const SET_COACH_SPRINT_ANALYTICS = 'SET_COACH_SPRINT_ANALYTICS';
export const SET_COACH_SPRINTS_ANALYTICS = 'SET_COACH_SPRINTS_ANALYTICS';
export const UPDATE_COACH_SPRINT = 'UPDATE_COACH_SPRINT';
export const SWITCH_CUSTOMERS = 'SWITCH_CUSTOMERS';
export const MERGE_CUSTOMERS = 'MERGE_CUSTOMERS';
export const UPDATE_SPRINTS = 'UPDATE_SPRINTS';
export const DELETE_COACH_SPRINT = 'DELETE_COACH_SPRINT';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const SET_FEEDBACK = 'SET_FEEDBACK';
export const REVIEW_FEEDBACK = 'REVIEW_FEEDBACK';
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK';
export const SET_EMAIL_TEMPLATE = 'SET_EMAIL_TEMPLATE';
export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE';
export const SAVE_EMAIL_TEMPLATE = 'SAVE_EMAIL_TEMPLATE';
export const LOADING_STATISTICS = 'LOADING_STATISTICS';
export const SET_ANALYTICS_STATISTICS = 'SET_ANALYTICS_STATISTICS';
export const GET_ANALYTICS_STATISTICS = 'GET_ANALYTICS_STATISTICS';


// USER SPRINT ACTIONS
export const GET_USER_SPRINT = 'GET_USER_SPRINT';
export const SET_USER_SPRINT = 'SET_USER_SPRINT';
export const GET_USER_SPRINTS = 'GET_USER_SPRINTS';
export const SET_USER_SPRINTS = 'SET_USER_SPRINTS';
export const UPDATE_SPRINT = 'UPDATE_SPRINT';
export const REQUEST_MAPS = 'REQUEST_MAPS';
export const CREATE_MAP = 'CREATE_MAP';
export const SET_MAPS = 'SET_MAPS';
export const SAVE_TEXT_ENTRY = 'SAVE_TEXT_ENTRY';
export const DELETE_TEXT_ENTRY = 'DELETE_TEXT_ENTRY';
export const UPDATE_TEXT_ENTRY = 'UPDATE_TEXT_ENTRY';
export const SAVE_BIG_ASSUMPTION = 'SAVE_BIG_ASSUMPTION';
export const DELETE_BIG_ASSUMPTION = 'DELETE_BIG_ASSUMPTION';
export const SAVE_GOAL = 'SAVE_GOAL';
export const DELETE_GOAL = 'DELETE_GOAL';
export const SET_EMPTY_GOAL = 'SET_EMPTY_GOAL';
export const DELETE_BUSINESS_GOAL = 'DELETE_BUSINESS_GOAL';
export const SET_EMPTY_BUSINESS_GOAL = 'SET_EMPTY_BUSINESS_GOAL';
export const UPDATE_GOAL_NOTES = 'UPDATE_GOAL_NOTES';
export const SET_GOAL_NOTES = 'SET_GOAL_NOTES';
export const SAVE_EXPERIMENT_ENTRY = 'SAVE_EXPERIMENT_ENTRY';
export const UPDATE_EXPERIMENT_ENTRY = 'UPDATE_EXPERIMENT_ENTRY';
export const SET_EXPERIMENTS = 'SET_EXPERIMENTS';
export const CREATE_EXPERIMENT = 'CREATE_EXPERIMENT';
export const UPDATE_EXPERIMENTS = 'UPDATE_EXPERIMENTS';
export const UPDATE_EXPERIMENT = 'UPDATE_EXPERIMENT';
export const TOGGLE_EXPERIMENT = 'TOGGLE_EXPERIMENT';
export const DELETE_EXPERIMENT = 'DELETE_EXPERIMENT';
export const SET_UPDATED_EXPERIMENT = 'SET_UPDATED_EXPERIMENT';
export const UPDATE_BIG_ASSUMPTIONS = 'UPDATE_BIG_ASSUMPTIONS';
export const UPDATE_BIG_ASSUMPTION = 'UPDATE_BIG_ASSUMPTION';
export const UPDATE_BIG_ASSUMPTIONS_ORDER = 'UPDATE_BIG_ASSUMPTIONS_ORDER';
export const UPDATE_TEXT_ENTRIES_ORDER = 'UPDATE_TEXT_ENTRIES_ORDER';
export const UPDATE_EXPERIMENTS_ORDER = 'UPDATE_EXPERIMENTS_ORDER';
export const UPDATE_TAKING_STOCK_QUESTION = 'UPDATE_TAKING_STOCK_QUESTION';
export const UPDATE_TAKING_STOCK_RANGE_QUESTION =
  'UPDATE_TAKING_STOCK_RANGE_QUESTION';
export const DELETE_TAKING_STOCK_QUESTION_ENTRY =
  'DELETE_TAKING_STOCK_QUESTION_ENTRY';
export const SET_QUESTION_FOR_TAKING_STOCK = 'SET_QUESTION_FOR_TAKING_STOCK';
export const TOGGLE_BIG_ASSUMPTION = 'TOGGLE_BIG_ASSUMPTION';
export const TOGGLE_BUSINESS_GOAL = 'TOGGLE_BUSINESS_GOAL';
export const SET_BUSINESS_GOAL_COLLAPSED = 'SET_BUSINESS_GOAL_COLLAPSED';
export const UPDATE_EXPERIMENT_IMPACT = 'UPDATE_EXPERIMENT_IMPACT';
export const SET_EXPERIMENT_IMPACT = 'SET_EXPERIMENT_IMPACT';

// Workouts
export const GET_USER_WORKOUTS = 'GET_USER_WORKOUTS';
export const SET_USER_WORKOUTS = 'SET_USER_WORKOUTS';
export const GET_USER_WORKOUT = 'GET_USER_WORKOUT';
export const REMOVE_USER_WORKOUT = 'REMOVE_USER_WORKOUT';
export const REMOVE_USER_WORKOUTS = 'REMOVE_USER_WORKOUTS';
export const REPLACE_USER_WORKOUTS = 'REPLACE_USER_WORKOUTS';
export const DELETE_USER_WORKOUT = 'DELETE_USER_WORKOUT';
export const DELETE_USER_WORKOUTS = 'DELETE_USER_WORKOUTS';
export const UPDATE_USER_WORKOUTS = 'UPDATE_USER_WORKOUTS';
export const CREATE_USER_WORKOUTS = 'CREATE_USER_WORKOUTS';
export const ADD_USER_WORKOUTS = 'ADD_USER_WORKOUTS';
export const SET_USER_WORKOUT = 'SET_USER_WORKOUT';
export const GET_WORKOUTS = 'GET_WORKOUTS';
export const SET_WORKOUTS = 'SET_WORKOUTS';
export const SAVE_WORKOUT_BIG_ASSUMPTION = 'SAVE_WORKOUT_BIG_ASSUMPTION';
export const DELETE_WORKOUT_BIG_ASSUMPTION = 'DELETE_WORKOUT_BIG_ASSUMPTION';
export const SET_WORKOUT_BIG_ASSUMPTION = 'SET_WORKOUT_BIG_ASSUMPTION';
export const REMOVE_WORKOUT_BIG_ASSUMPTION = 'REMOVE_WORKOUT_BIG_ASSUMPTION';
export const DELETE_WORKOUT_EXPERIMENT = 'DELETE_WORKOUT_EXPERIMENT';
export const SAVE_WORKOUT_EXPERIMENT = 'SAVE_WORKOUT_EXPERIMENT';
export const SET_WORKOUT_EXPERIMENT = 'SET_WORKOUT_EXPERIMENT';
export const UPDATE_WORKOUT_EXPERIMENT = 'UPDATE_WORKOUT_EXPERIMENT';
export const REMOVE_WORKOUT_EXPERIMENT = 'REMOVE_WORKOUT_EXPERIMENT';
export const UPDATE_WORKOUT_BIG_ASSUMPTION = 'UPDATE_WORKOUT_BIG_ASSUMPTION';
export const TOGGLE_WORKOUT_BIG_ASSUMPTION = 'TOGGLE_WORKOUT_BIG_ASSUMPTION';
export const SAVE_WORKOUT_NOTE = 'SAVE_WORKOUT_NOTE';
export const SAVE_WORKOUT_QUESTION = 'SAVE_WORKOUT_QUESTION';
export const SET_WORKOUT_PURPOSE_NOTES = 'SET_WORKOUT_PURPOSE_NOTES';
export const DELETE_WORKOUT_NOTE = 'DELETE_WORKOUT_NOTE';
export const REMOVE_WORKOUT_NOTE = 'REMOVE_WORKOUT_NOTE';
export const SET_WORKOUT_CHALLENGE_NOTES = 'SET_WORKOUT_CHALLENGE_NOTES';
export const SET_WORKOUT_CHALLENGE_QUESTION = 'SET_WORKOUT_CHALLENGE_QUESTION';
export const SAVE_WORKOUT_CHALLENGE_NOTE = 'SAVE_WORKOUT_CHALLENGE_NOTE';
export const DELETE_WORKOUT_CHALLENGE_NOTE = 'DELETE_WORKOUT_CHALLENGE_NOTE';
export const REMOVE_WORKOUT_CHALLENGE_NOTE = 'REMOVE_WORKOUT_CHALLENGE_NOTE';
export const SET_WORKOUT_SESSION = 'SET_WORKOUT_SESSION';
export const TOGGLE_WORKOUT_EXPERIMENT = 'TOGGLE_WORKOUT_EXPERIMENT';
export const SAVE_TOGGLE_WORKOUT_EXPERIMENT = 'SAVE_TOGGLE_WORKOUT_EXPERIMENT';
export const CREATE_WORKOUT_EXPERIMENT = 'CREATE_WORKOUT_EXPERIMENT';
export const UPDATE_WORKOUT_EXPERIMENTS = 'UPDATE_WORKOUT_EXPERIMENTS';
export const SET_UPDATED_WORKOUT_EXPERIMENT = 'SET_UPDATED_WORKOUT_EXPERIMENT';

// Developmental Workouts
export const GET_USER_DEV_WORKOUTS = 'GET_USER_DEV_WORKOUTS';
export const SET_USER_DEV_WORKOUTS = 'SET_USER_DEV_WORKOUTS';
export const GET_USER_DEV_WORKOUT = 'GET_USER_DEV_WORKOUT';
export const REMOVE_USER_DEV_WORKOUT = 'REMOVE_USER_DEV_WORKOUT';
export const REMOVE_USER_DEV_WORKOUTS = 'REMOVE_USER_DEV_WORKOUTS';
export const REPLACE_USER_DEV_WORKOUTS = 'REPLACE_USER_DEV_WORKOUTS';
export const DELETE_USER_DEV_WORKOUT = 'DELETE_USER_DEV_WORKOUT';
export const DELETE_USER_DEV_WORKOUTS = 'DELETE_USER_DEV_WORKOUTS';
export const UPDATE_USER_DEV_WORKOUTS = 'UPDATE_USER_DEV_WORKOUTS';
export const CREATE_USER_DEV_WORKOUTS = 'CREATE_USER_DEV_WORKOUTS';
export const ADD_USER_DEV_WORKOUTS = 'ADD_USER_DEV_WORKOUTS';
export const SET_USER_DEV_WORKOUT = 'SET_USER_DEV_WORKOUT';
export const GET_DEV_WORKOUTS = 'GET_DEV_WORKOUTS';
export const SET_DEV_WORKOUTS = 'SET_DEV_WORKOUTS';
export const SAVE_DEV_WORKOUT_BIG_ASSUMPTION = 'SAVE_DEV_WORKOUT_BIG_ASSUMPTION';
export const DELETE_DEV_WORKOUT_BIG_ASSUMPTION = 'DELETE_DEV_WORKOUT_BIG_ASSUMPTION';
export const SET_DEV_WORKOUT_BIG_ASSUMPTION = 'SET_DEV_WORKOUT_BIG_ASSUMPTION';
export const REMOVE_DEV_WORKOUT_BIG_ASSUMPTION = 'REMOVE_DEV_WORKOUT_BIG_ASSUMPTION';
export const DELETE_DEV_WORKOUT_EXPERIMENT = 'DELETE_DEV_WORKOUT_EXPERIMENT';
export const SAVE_DEV_WORKOUT_EXPERIMENT = 'SAVE_DEV_WORKOUT_EXPERIMENT';
export const SET_DEV_WORKOUT_EXPERIMENT = 'SET_DEV_WORKOUT_EXPERIMENT';
export const UPDATE_DEV_WORKOUT_EXPERIMENT = 'UPDATE_DEV_WORKOUT_EXPERIMENT';
export const REMOVE_DEV_WORKOUT_EXPERIMENT = 'REMOVE_DEV_WORKOUT_EXPERIMENT';
export const UPDATE_DEV_WORKOUT_BIG_ASSUMPTION = 'UPDATE_DEV_WORKOUT_BIG_ASSUMPTION';
export const TOGGLE_DEV_WORKOUT_BIG_ASSUMPTION = 'TOGGLE_DEV_WORKOUT_BIG_ASSUMPTION';
export const SAVE_DEV_WORKOUT_CHALLENGE_NOTE = 'SAVE_DEV_WORKOUT_CHALLENGE_NOTE';
export const DELETE_DEV_WORKOUT_CHALLENGE_NOTE = 'DELETE_DEV_WORKOUT_CHALLENGE_NOTE';
export const REMOVE_DEV_WORKOUT_CHALLENGE_NOTE = 'REMOVE_DEV_WORKOUT_CHALLENGE_NOTE';
export const SET_DEV_WORKOUT_SESSION = 'SET_DEV_WORKOUT_SESSION';
export const RESET_USER_DEV_WORKOUTS = 'RESET_USER_DEV_WORKOUTS';
export const UPDATE_USER_DEV_WORKOUT = 'UPDATE_USER_DEV_WORKOUT';

export const CREATE_DEV_WORKOUT_EXPERIMENT = 'CREATE_DEV_WORKOUT_EXPERIMENT';
export const TOGGLE_DEV_WORKOUT_EXPERIMENT = 'TOGGLE_DEV_WORKOUT_EXPERIMENT';
export const SAVE_TOGGLE_DEV_WORKOUT_EXPERIMENT = 'SAVE_TOGGLE_DEV_WORKOUT_EXPERIMENT';
export const UPDATE_DEV_WORKOUT_EXPERIMENTS = 'UPDATE_DEV_WORKOUT_EXPERIMENTS';
export const SET_UPDATED_DEV_WORKOUT_EXPERIMENT = 'SET_UPDATED_DEV_WORKOUT_EXPERIMENT';

export const SAVE_DEV_WORKOUT_TITLE = 'SAVE_DEV_WORKOUT_TITLE';
export const CREATE_DEV_WORKOUT_ENTRY = 'CREATE_DEV_WORKOUT_ENTRY';
export const UPDATE_DEV_WORKOUT_ENTRY = 'UPDATE_DEV_WORKOUT_ENTRY';
export const DELETE_DEV_WORKOUT_ENTRY = 'DELETE_DEV_WORKOUT_ENTRY';
export const UPDATE_DEV_WORKOUT_ENTRIES_ORDER = 'UPDATE_DEV_WORKOUT_ENTRIES_ORDER';
export const GET_ALL_DEV_WORKOUTS = 'GET_ALL_DEV_WORKOUTS';
export const SET_ALL_DEV_WORKOUTS = 'SET_ALL_DEV_WORKOUTS';
export const ADD_DEV_WORKOUT_CHALLENGE = 'ADD_DEV_WORKOUT_CHALLENGE';
export const SET_DEV_WORKOUT_EXPERIMENT_IMPACT = 'SET_DEV_WORKOUT_EXPERIMENT_IMPACT';

export const UPDATE_WORKOUT_TAKING_STOCK_QUESTION = 'UPDATE_WORKOUT_TAKING_STOCK_QUESTION';
export const DELETE_WORKOUT_TAKING_STOCK_QUESTION_ENTRY = 'DELETE_WORKOUT_TAKING_STOCK_QUESTION_ENTRY';
export const SET_QUESTION_FOR_WORKOUT_TAKING_STOCK = 'SET_QUESTION_FOR_WORKOUT_TAKING_STOCK';
export const UPDATE_WORKOUT_TAKING_STOCK_RANGE_QUESTION_ENTRY = 'UPDATE_WORKOUT_TAKING_STOCK_RANGE_QUESTION_ENTRY';

// Pods
export const GET_USER_PODS = 'GET_USER_PODS';
export const SET_USER_PODS = 'SET_USER_PODS';
export const GET_USER_POD = 'GET_USER_POD';
export const SET_USER_POD = 'SET_USER_POD';
export const GET_PODS = 'GET_PODS';
export const SET_PODS = 'SET_PODS';
export const SAVE_POD_BIG_ASSUMPTION = 'SAVE_POD_BIG_ASSUMPTION';
export const DELETE_POD_BIG_ASSUMPTION = 'DELETE_POD_BIG_ASSUMPTION';
export const SET_POD_BIG_ASSUMPTION = 'SET_POD_BIG_ASSUMPTION';
export const REMOVE_POD_BIG_ASSUMPTION = 'REMOVE_POD_BIG_ASSUMPTION';
export const DELETE_POD_EXPERIMENT = 'DELETE_POD_EXPERIMENT';
export const SAVE_POD_EXPERIMENT = 'SAVE_POD_EXPERIMENT';
export const SET_POD_EXPERIMENT = 'SET_POD_EXPERIMENT';
export const UPDATE_POD_EXPERIMENT = 'UPDATE_POD_EXPERIMENT';
export const REMOVE_POD_EXPERIMENT = 'REMOVE_POD_EXPERIMENT';
export const UPDATE_POD_BIG_ASSUMPTION = 'UPDATE_POD_BIG_ASSUMPTION';
export const TOGGLE_POD_BIG_ASSUMPTION = 'TOGGLE_POD_BIG_ASSUMPTION';
export const SAVE_POD_NOTE = 'SAVE_POD_NOTE';
export const SET_POD_START_NOTES = 'SET_POD_START_NOTES';
export const DELETE_POD_NOTE = 'DELETE_POD_NOTE';
export const REMOVE_POD_NOTE = 'REMOVE_POD_NOTE';
export const SET_POD_DEVELOPMENT_NOTES = 'SET_POD_DEVELOPMENT_NOTES';
export const SAVE_POD_DEVELOPMENT_NOTE = 'SAVE_POD_DEVELOPMENT_NOTE';
export const DELETE_POD_DEVELOPMENT_NOTE = 'DELETE_POD_DEVELOPMENT_NOTE';
export const REMOVE_POD_DEVELOPMENT_NOTE = 'REMOVE_POD_DEVELOPMENT_NOTE';
export const SET_POD_SESSION = 'SET_POD_SESSION';
export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION';

// NFFs
export const SET_NFFS = 'SET_NFFS';
export const GET_NFFS = 'GET_NFFS';
export const GET_USER_NFF = 'GET_USER_NFF';
export const SET_USER_NFF = 'SET_USER_NFF';
export const ADD_USER_NFFS = 'ADD_USER_NFFS';
export const SET_USER_NFFS = 'SET_USER_NFFS';
export const CREATE_USER_NFFS = 'CREATE_USER_NFFS';
export const UPDATE_USER_NFFS = 'UPDATE_USER_NFFS';
export const DELETE_USER_NFFS = 'DELETE_USER_NFFS';
export const GET_USER_NFFS = 'GET_USER_NFFS';
export const DELETE_USER_NFF = 'DELETE_USER_NFF';
export const SAVE_USER_NFF_TITLE = 'SAVE_USER_NFF_TITLE';
export const REPLACE_USER_NFFS = 'REPLACE_USER_NFFS';
export const REMOVE_USER_NFFS = 'REMOVE_USER_NFFS';
export const REMOVE_USER_NFF = 'REMOVE_USER_NFF';
export const GET_USER_FORMULA = 'GET_USER_FORMULA';
export const SET_USER_FORMULA = 'SET_USER_FORMULA';
export const REPLACE_USER_FROMULA = 'REPLACE_USER_FROMULA';
export const UPDATE_USER_FROMULA = 'UPDATE_USER_FROMULA';
export const CREATE_FORMULA_ENTRY = 'CREATE_FORMULA_ENTRY';
export const UPDATE_FORMULA_ENTRY = 'UPDATE_FORMULA_ENTRY';
export const DELETE_FORMULA_ENTRY = 'DELETE_FORMULA_ENTRY';
export const UPDATE_FORMULA_ENTRIES_ORDER = 'UPDATE_FORMULA_ENTRIES_ORDER';
export const UPDATE_NFF_TOPIC = 'UPDATE_NFF_TOPIC';
export const ADD_NEW_FORMULA = 'ADD_NEW_FORMULA';
export const TOGGLE_FORMULA = 'TOGGLE_FORMULA';
export const DELETE_FORMULA = 'DELETE_FORMULA';
export const CREATE_USER_NFF = 'CREATE_USER_NFF';
export const UPDATE_USER_NFF = 'UPDATE_USER_NFF';
export const UPDATE_FORMULA_ORDER = 'UPDATE_FORMULA_ORDER';
export const RESET_USER_NFFS = 'RESET_USER_NFFS';

//Messages
export const MESSAGES_CLEAR = 'MESSAGES_CLEAR';
export const ON_MESSAGE = 'ON_MESSAGE';

export const LOGGED_IN = 'LOGGED_IN';

export const NEW_MAPS_REQUEST = 'NEW_MAPS_REQUEST';
export const NEW_MAPS_SUCCESS = 'NEW_MAPS_SUCCESS';
export const MAP_UPDATE_REQUEST_REQUEST = 'MAP_UPDATE_REQUEST_REQUEST';
export const MAP_UPDATE_REQUEST_SUCCESS = 'MAP_UPDATE_REQUEST_SUCCESS';

//Admins
export const GET_ADMINS = 'GET_ADMINS';
export const SET_ADMINS = 'SET_ADMINS';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const REMOVE_ADMIN = 'REMOVE_ADMIN';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const ADD_ADMIN = 'ADD_ADMIN';

//Managers 
export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_MANAGER = 'GET_MANAGER';
export const SET_MANAGERS = 'SET_MANAGERS';
export const DELETE_MANAGER = 'DELETE_MANAGER';
export const ADD_MANAGER = 'ADD_MANAGER';
export const REMOVE_MANAGER = 'REMOVE_MANAGER';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const SET_UPDATED_MANAGER = 'SET_UPDATED_MANAGER';

//Dashboard
export const ON_SECTION_CHANGE = 'ON_SECTION_CHANGE';

export const ON_API_ERROR = 'ON_API_ERROR';

// Options
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SAVE_LANGUAGE = 'SAVE_LANGUAGE';
