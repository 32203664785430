import {all} from 'redux-saga/effects';
import {
  getAdminSignInWatcher,
  createNewSprintWatcher,
  getSprintsWatcher,
  getSprintsCompaniesWatcher,
  getWorkoutsWatcher,
  getNffsWatcher,
  getCoachSprintWatcher,
  updateCoachSprintWatcher,
  deleteCoachSprintWatcher,
  getFeedbackWatcher,
  getEmailTemplateWatcher,
  saveEmailTemplateWatcher,
  reviewFeedbackWatcher,
  getRequestsWatcher,
  requestUpdateWatcher,
  getSprintDataWatcher,
  getSprintDataManyWatcher,
  getAdminsWatcher,
  removeAdminWatcher,
  createAdminWatcher,
  getManagersWatcher,
  deleteManagersWatcher,
  updateManagerWatcher,
  getStatisticsWatcher,
  getDevWorkoutsWatcher,
} from './admin';

import {
  getSprintWatcher,
  getUserSprintsWatcher,
  requestMapsWatcher,
  saveTextEntryWatcher,
  deleteTextEntryWatcher,
  saveBigAssumptionWatcher,
  deleteBigAssumptionWatcher,
  saveGoalWatcher,
  saveExperimentEntryWatcher,
  updateExperimentEntryWatcher,
  updateBigAssumptionsOrderWatcher,
  updateTextEntriesOrderWatcher,
  updateExperimentsOrderWatcher,
  updateTakingStockQuestionWatcher,
  deleteTakingStockQuestionEntryWatcher,
  deleteExperimentWatcher,
  deleteGoalWatcher,
  deleteBusinessGoalWatcher,
  updateTakingStockRangeQuestionWatcher,
  toggleBigAssumptionWatcher,
  createMapsWatcher,
  updateSprintWatcher,
  toggleBusinessGoalWatcher,
  updateGoalNotesWatcher,
  toggleExperimentWatcher,
  createNewExperimentWatcher,
  updateExperimentImpactWatcher,
} from './sprint';

import {
  getLogInWatcher,
  getSingUpWatcher,
  getInvitationDataWatcher,
  forgotPasswordRequestWatcher,
  checkResetCodeWatcher,
  resetPasswordWatcher,
  changePasswordWatcher,
  updateProfileWatcher,
  agreementWatcher,
  setLanguageWatcher,
} from './auth';

import {
  getUserWorkoutsWatcher,
  getUserWorkoutWatcher,
  deleteUserWorkoutWatcher,
  deleteUserWorkoutsWatcher,
  createUserWorkoutsWatcher,
  saveWorkoutAssumptionWatcher,
  deleteWorkoutAssumptionWatcher,
  saveWorkoutExperimentWatcher,
  deleteWorkoutExperimentWatcher,
  toggleWorkoutAssumptionWatcher,
  updateWorkoutExperimentWatcher,
  saveWorkoutNoteWatcher,
  deleteWorkoutNoteWatcher,
  saveWorkoutNoteChallengeWatcher,
  deleteWorkoutNoteChallengeWatcher,
  savePodAssumptionWatcher,
  deletePodAssumptionWatcher,
  savePodExperimentWatcher,
  updatePodExperimentWatcher,
  deletePodExperimentWatcher,
  togglePodAssumptionWatcher,
  savePodNoteDevelopmentWatcher,
  deletePodNoteDevelopmentWatcher,
  saveWorkoutQuestionWatcher,
  updateUserWorkoutsWatcher,
  toggleWorkoutExperimentWatcher,
  createNewWorkoutExperimentWatcher,
} from './workout';

import {
  deleteUserNffWatcher,
  deleteUserNffsWatcher,
  updateUserNffsWatcher,
  createUserNffsWatcher,
  getUserNffsWatcher,
  saveUserNffTitleWatcher,
  updateFormulaWatcher,
  createFormulaEntryWatcher,
  updateFormulaEntryWatcher,
  deleteFormulaEntryWatcher,
  updateFormulaEntriesOrderWatcher,
  addNewFormulaWatcher,
  getUserNffWatcher,
  updateTopicWatcher,
  toggleFormulaWatcher,
  deleteFormulaWatcher,
  createNffWatcher,
  updateNffWatcher,
  resetNffWatcher,
} from './nff';

import {
  getUserDevWorkoutWatcher,
  deleteUserDevWorkoutWatcher,
  deleteUserDevWorkoutsWatcher,
  updateUserDevWorkoutsWatcher,
  createUserDevWorkoutsWatcher,
  getUserDevWorkoutsWatcher,

  updateTitleWatcher,
  createDevWorkoutEntryWatcher,
  updateDevWorkoutEntryWatcher,
  deleteDevWorkoutEntryWatcher,
  updateDevWorkoutEntriesOrderWatcher,
  addNewChallengeWatcher,

  saveDevWorkoutAssumptionWatcher,
  toggleDevWorkoutAssumptionWatcher,
  toggleDevWorkoutExperimentWatcher,
  createNewDevWorkoutExperimentWatcher,
  deleteDevWorkoutExperimentWatcher,
  saveDevWorkoutExperimentWatcher,
  updateDevWorkoutExperimentWatcher,
  deleteDevWorkoutAssumptionWatcher,
  resetWorkoutsWatcher,
  updateUserWorkoutWatcher,
  updateWorkoutTakingStockQuestionWatcher,
  deleteWorkoutTakingStockQuestionEntryWatcher,
  updateWorkoutTakingStockRangeQuestionWatcher,
} from './developmentalWorkout';

import {
  getUserPodsWatcher,
  getUserPodWatcher,
  savePodNoteWatcher,
  deletePodNoteWatcher,
} from './pod';

export default function* IndexSaga() {
  yield all([
    getLogInWatcher(),
    getSingUpWatcher(),
    getAdminSignInWatcher(),
    createNewSprintWatcher(),
    getSprintsWatcher(),
    getSprintsCompaniesWatcher(),
    getWorkoutsWatcher(),
    getNffsWatcher(),
    getSprintWatcher(),
    updateSprintWatcher(),
    getUserSprintsWatcher(),
    requestMapsWatcher(),
    getCoachSprintWatcher(),
    getSprintDataWatcher(),
    getSprintDataManyWatcher(),
    updateCoachSprintWatcher(),
    deleteCoachSprintWatcher(),
    getFeedbackWatcher(),
    getEmailTemplateWatcher(),
    saveEmailTemplateWatcher(),
    reviewFeedbackWatcher(),
    saveTextEntryWatcher(),
    deleteTextEntryWatcher(),
    saveBigAssumptionWatcher(),
    deleteBigAssumptionWatcher(),
    deleteExperimentWatcher(),
    deleteGoalWatcher(),
    deleteBusinessGoalWatcher(),
    saveGoalWatcher(),
    updateGoalNotesWatcher(),
    createNewExperimentWatcher(),
    saveExperimentEntryWatcher(),
    updateExperimentEntryWatcher(),
    updateBigAssumptionsOrderWatcher(),
    updateTextEntriesOrderWatcher(),
    updateExperimentsOrderWatcher(),
    updateTakingStockQuestionWatcher(),
    deleteTakingStockQuestionEntryWatcher(),
    updateTakingStockRangeQuestionWatcher(),
    toggleBigAssumptionWatcher(),
    toggleExperimentWatcher(),
    getInvitationDataWatcher(),
    forgotPasswordRequestWatcher(),
    checkResetCodeWatcher(),
    resetPasswordWatcher(),
    changePasswordWatcher(),
    updateProfileWatcher(),
    getRequestsWatcher(),
    requestUpdateWatcher(),
    createMapsWatcher(),
    agreementWatcher(),
    getUserWorkoutsWatcher(),
    getUserWorkoutWatcher(),
    saveWorkoutAssumptionWatcher(),
    deleteWorkoutAssumptionWatcher(),
    saveWorkoutExperimentWatcher(),
    deleteWorkoutExperimentWatcher(),
    toggleWorkoutAssumptionWatcher(),
    updateWorkoutExperimentWatcher(),
    saveWorkoutNoteWatcher(),
    deleteWorkoutNoteWatcher(),
    saveWorkoutNoteChallengeWatcher(),
    toggleWorkoutExperimentWatcher(),
    createNewWorkoutExperimentWatcher(),
    deleteWorkoutNoteChallengeWatcher(),
    getUserPodsWatcher(),
    getUserPodWatcher(),
    savePodNoteDevelopmentWatcher(),
    deletePodNoteDevelopmentWatcher(),
    savePodNoteWatcher(),
    deletePodNoteWatcher(),
    savePodAssumptionWatcher(),
    deletePodAssumptionWatcher(),
    savePodExperimentWatcher(),
    updatePodExperimentWatcher(),
    deletePodExperimentWatcher(),
    togglePodAssumptionWatcher(),
    deleteUserWorkoutWatcher(),
    deleteUserWorkoutsWatcher(),
    createUserWorkoutsWatcher(),
    saveWorkoutQuestionWatcher(),
    getAdminsWatcher(),
    getManagersWatcher(),
    updateManagerWatcher(),
    deleteManagersWatcher(),
    removeAdminWatcher(),
    createAdminWatcher(),
    updateUserWorkoutsWatcher(),
    toggleBusinessGoalWatcher(),
    setLanguageWatcher(),
    getStatisticsWatcher(),
    // getUserFormulaWatcher(),
    deleteUserNffWatcher(),
    deleteUserNffsWatcher(),
    updateUserNffsWatcher(),
    createUserNffsWatcher(),
    getUserNffsWatcher(),
    saveUserNffTitleWatcher(),
    updateFormulaWatcher(),
    createFormulaEntryWatcher(),
    updateFormulaEntryWatcher(),
    deleteFormulaEntryWatcher(),
    updateFormulaEntriesOrderWatcher(),
    addNewFormulaWatcher(),
    getUserNffWatcher(),
    updateTopicWatcher(),
    toggleFormulaWatcher(),
    deleteFormulaWatcher(),
    createNffWatcher(),
    updateNffWatcher(),
    resetNffWatcher(),

    getUserDevWorkoutWatcher(),
    deleteUserDevWorkoutWatcher(),
    deleteUserDevWorkoutsWatcher(),
    updateUserDevWorkoutsWatcher(),
    createUserDevWorkoutsWatcher(),
    getUserDevWorkoutsWatcher(),
    updateTitleWatcher(),
    createDevWorkoutEntryWatcher(),
    updateDevWorkoutEntryWatcher(),
    deleteDevWorkoutEntryWatcher(),
    updateDevWorkoutEntriesOrderWatcher(),
    getDevWorkoutsWatcher(),
    addNewChallengeWatcher(),
    saveDevWorkoutAssumptionWatcher(),
    toggleDevWorkoutAssumptionWatcher(),
    toggleDevWorkoutExperimentWatcher(),
    createNewDevWorkoutExperimentWatcher(),
    deleteDevWorkoutExperimentWatcher(),
    saveDevWorkoutExperimentWatcher(),
    updateDevWorkoutExperimentWatcher(),
    deleteDevWorkoutAssumptionWatcher(),
    resetWorkoutsWatcher(),
    updateUserWorkoutWatcher(),
    updateExperimentImpactWatcher(),
    updateWorkoutTakingStockQuestionWatcher(),
    deleteWorkoutTakingStockQuestionEntryWatcher(),
    updateWorkoutTakingStockRangeQuestionWatcher(),
  ]);
}
